import { Button, Input } from "antd";

import { Box } from "components/Box";
import React from "react";

const { TextArea } = Input;

export const Form = () => {
  return (
    <Box>
      <form
        id="form"
        method="POST"
        action="https://sayer.legtux.org/lindederickx/contact.php"
      >
        <Box mb="12px">
          <Input type="text" name="name" placeholder="Your name *" required />
        </Box>
        <Box mb="12px">
          <Input
            type="email"
            name="email"
            placeholder="Your email *"
            required
          />
        </Box>
        <Box mb="12px">
          <Input type="text" name="subject" placeholder="Subject" />
        </Box>
        <Box mb="12px">
          <TextArea name="message" rows="3" placeholder="Message *" required />
        </Box>
        <Button htmlType="submit" type="primary">
          SEND
        </Button>
      </form>
    </Box>
  );
};
