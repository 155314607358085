import { Box } from "components/Box";
import { MAX_COLUMN_WIDTH } from "utils/config";
import React from "react";

export const Image = ({ path, max = `${MAX_COLUMN_WIDTH}px`,  }) => {
  return (
    <Box
      maxWidth={max}
      forwardedAs="img"
      src={process.env.PUBLIC_URL + path}
      width="100%"
    />
  );
};
