import { Box } from "components/Box";
import { Carousel } from "antd";
import React from "react";
import { useWindowSize } from "hooks/useWindowSize";

const Image = ({ path }) => {
  const { smallWindow } = useWindowSize();

  return (
    <Box height={!smallWindow && "100vh"}>
      <Box
        forwardedAs="img"
        objectFit="contain"
        width={smallWindow ? "100%" : `calc(100% - 24px)`}
        height={!smallWindow && "calc(100vh - 48px)"}
        alt=""
        src={process.env.PUBLIC_URL + path}
      />
    </Box>
  );
};

export const Home = () => {
  const { smallWindow } = useWindowSize();

  return (
    <Carousel
      autoplay
      dots={false}
      style={{
        height: !smallWindow && "100vh",
        maxHeight: !smallWindow && "100vh",
        minHeight: !smallWindow && "100vh",
      }}
    >
      <Image path="/images/caroussel/1.jpg" />
      <Image path="/images/caroussel/2.jpg" />
      <Image path="/images/caroussel/3.jpg" />
      <Image path="/images/caroussel/4.jpg" />
      <Image path="/images/caroussel/5.jpg" />
      <Image path="/images/caroussel/6.jpg" />
      <Image path="/images/caroussel/7.jpg" />
      <Image path="/images/caroussel/8.jpg" />
      <Image path="/images/caroussel/9.jpg" />
    </Carousel>
  );
};
