import { Align } from "components/Align";
import { Grid } from "components/Grid";
import { Image } from "components/Image";
import React from "react";

export const Objects = () => {
  const content = [
    <Grid
      elements={[
        <Image path="/images/objects/3.jpg" />,
        <Image path="/images/objects/4.jpg" />,
      ]}
    />,
    {
      textAlign: "center",
      className: "title",
      children: "Reverse Mushroom, 2014",
    },
    <Grid
    elements={[
      <Image path="/images/objects/IMG_4415.jpg" />,
      <Image path="/images/objects/IMG_4417.jpg" />,
    ]}
  />,
    { textAlign: "center", className: "title", children: "Mushroom, 2020" },
    <Grid
      elements={[
        <Image path="/images/objects/IMG_1096.jpg" />,
        <Image path="/images/objects/IMG_1092.jpg" />,
      ]}
    />,
    { textAlign: "center", className: "title", children: "2020" },
    <Grid
      elements={[
        <Image path="/images/objects/IMG_4412.jpg" />,
        <Image path="/images/objects/IMG_4413.jpg" />,
      ]}
    />,
    { textAlign: "center", className: "title", children: "Vanes, 2020" },
    {
      textAlign: "center",
      children: <Image path="/images/objects/IMG_4414.jpg" />,
    },
    <Grid
      elements={[
        <Image path="/images/objects/IMG_2806.jpg" />,
        <Image path="/images/objects/IMG_2816.jpg" />,
      ]}
    />,
    { textAlign: "center", className: "title", children: "Bowls, 2015" },
  ];

  return <Align elements={content} />;
};
