import { Align } from "components/Align";
import { Image } from "components/Image";
import React from "react";

export const ThankYou = () => {
  return (
    <Align
      elements={[
        <Image path="/images/tables/DSC01468.jpg" max="100%" />,
        { className: "title", children: "CONTACT" },
        {
          textAlign: "justify",
          children:
            "Thank you for your message. We will be in touch soon.",
        },
      ]}
    />
  );
};
