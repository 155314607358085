import { Box } from "components/Box";
import React from "react";

export const Grid = ({ elements = [] }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(auto-fit, minmax(250px, 1fr))`}
      justifyItems="center"
      alignItems="center"
      gridGap="24px"
      width="100%"
    >
      {elements.map((element, index) => {
        if (Array.isArray(element)) {
          return (
            <Box
              display="grid"
              gridGap="24px"
              alignItems="center"
              justifyItems="center"
            >
              {element}
            </Box>
          );
        }
        return element;
      })}
    </Box>
  );
};
