import { Box } from "components/Box";
import { Grid } from "components/Grid";
import { Image } from "components/Image";
import React from "react";

export const About = () => {
  return (
    <Grid
      elements={[
        <Box textAlign="justify" alignSelf="start">
          <p className="title">ABOUT</p>
          <p>
            After finishing her Bachelor in interior architecture at the Art
            Academic in The Netherlands, Linde Derickx found herself traveling
            the world for years. During her travels she continuously found
            inspiration in architecture, materials or in the unusual elements of
            nature.
            <br />
            During experiment with these elements, she originates unconventional
            sculptural or architectural shapes that have obtained a certain
            sensuality yet playfulness in their balancing point between positive
            and negative shapes.
            <br />
            In her work she often likes to integrate this balancing point to
            invite interaction with the user.
            <br />
            In 2019 she founded C K X , based in her home country, the
            Netherlands.
          </p>
        </Box>,
        <Image path="/images/mirrors/IMG_3199.jpg" />,
      ]}
    />
  );
};
