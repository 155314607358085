import { Box } from "components/Box";
import React from "react";
import { isValidElement } from "react";

export const Align = ({ elements }) => {
  return elements.map((element, index) => {
    if (isValidElement(element)) {
      return <Box mt={index && "24px"}>{element}</Box>;
    }
    return <Box mt={index && "24px"} {...element} />;
  });
};
