import { Align } from "components/Align";
import { Box } from "components/Box";
import { Form } from "components/Form";
import { Grid } from "components/Grid";
import { Image } from "components/Image";
import { MAX_COLUMN_WIDTH } from "utils/config";
import React from "react";

export const WineRed = () => {
  const content = [
    <Grid
      elements={[
        <Image path="/images/tables/DSC01222.jpg" />,
        <Image path="/images/tables/DSC01207.jpg" />,
      ]}
    />,
    { textAlign: "center", className: "title", children: "DE-FORMA wine red" },
    {
      textAlign: "center",
      children: <Image path="/images/tables/DSC01211.jpg" />,
    },
    {
      textAlign: "center",
      children: (
        <Image path="/images/tables/1.jpg" max={`${MAX_COLUMN_WIDTH * 2}px`} />
      ),
    },
    <Grid
      elements={[
        <Image path="/images/tables/DSC00872.jpg" />,
        [
          <Image key={0} path="/images/tables/DSC00851.jpg" max="100%" />,
          <Image key={1} path="/images/tables/DSC01292.jpg" max="100%" />,
        ],
      ]}
    />,
    <Grid
      elements={[
        <Image path="/images/tables/DSC00881.jpg" />,
        [
          <Image key={0} path="/images/tables/DSC01279.jpg" max="100%" />,
          <Image key={1} path="/images/tables/DSC01261.jpg" max="100%" />,
        ],
      ]}
    />,
    {
      textAlign: "center",
      children: (
        <Image path="/images/tables/4.jpg" max={`${MAX_COLUMN_WIDTH * 2}px`} />
      ),
    },
    {
      textAlign: "center",
      children: "Module manual for your DE-FORMA salon table",
    },
    {
      textAlign: "center",
      children: <Image path="/images/tables/modules.png" />,
    },
    <Grid
      elements={[
        <Box maxWidth={`${MAX_COLUMN_WIDTH}px`}>
          <video autoPlay loop playsInline muted width="100%">
            <source
              src={process.env.PUBLIC_URL + "/videos/wine-red.mp4"}
              type="video/mp4"
            />
          </video>
        </Box>,
        <Box justifySelf="flex-start">
          <p>Available in three different variations:</p>
          <p>Wine red, Ivory white, Mat black</p>
          <p>Set dimensions:</p>
          <p>
            Table DE: 64 x 40 x 45 cm
            <br />
            Table FORMA: 57 x 33 x 51 cm
          </p>
          <p>Year: 2020</p>
        </Box>,
      ]}
    />,
    {
      textAlign: "center",
      children:
        "For orders and price inquiries, please fill out the following informations:",
    },
    {
      maxWidth: `${MAX_COLUMN_WIDTH * 1.5}px`,
      mx: "auto",
      children: <Form />,
    },
  ];

  return <Align elements={content} />;
};
