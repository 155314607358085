import { Menu as AntdMenu, Layout } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Box } from "components/Box";
import CKX from "pages/Menu/C K X.svg";
import React from "react";

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = AntdMenu;

export const Menu = ({ MENU_WIDTH }) => {
  let history = useHistory();
  let location = useLocation();
  const currentTab = location?.pathname?.split("/")?.[1];

  const handleClick = (event) => {
    if (event.key) {
      history.push(`/${event.key}`);
    }
  };

  return (
    <Sider
      style={{
        overflow: "hidden",
        position: "fixed",
        left: 0,
        padding: 0,
      }}
    >
      <Layout style={{ height: "100vh" }}>
        <Header>
          <Link to="/">
            <Box fontWeight="bold" fontSize="24px">
              <Box forwardedAs='img' alt='C K X' width='80px' src={CKX} />
            </Box>
          </Link>
        </Header>
        <Content />
        <Footer>
          <AntdMenu
            selectedKeys={[currentTab]}
            onClick={handleClick}
            mode="vertical"
          >
            <SubMenu key="products" title="products">
              <AntdMenu.Item key="tables">tables</AntdMenu.Item>
              <AntdMenu.Item key="mirrors">mirrors</AntdMenu.Item>
              <AntdMenu.Item key="objects">objects</AntdMenu.Item>
              <AntdMenu.Item key="artwork">artwork</AntdMenu.Item>
            </SubMenu>
            <AntdMenu.Item key="about">about C K X</AntdMenu.Item>
            <AntdMenu.Item key="contact">contact</AntdMenu.Item>
          </AntdMenu>
        </Footer>
      </Layout>
    </Sider>
  );
};
