import { Layout } from "antd";
import React from "react";
import { useWindowSize } from "hooks/useWindowSize";

const { Footer: AntdFooter } = Layout;

export const Footer = () => {
  const { smallWindow } = useWindowSize();

  return (
    <AntdFooter
      style={{ paddingLeft: smallWindow ? "24px" : 0 }}
      className="title"
    >
      LINDE DERICKX
      <br />
      LOCATION: THE NETHERLANDS
      <br />
      <br />
      INSTAGRAM:{" "}
      <a
        href="https://www.instagram.com/lindederi.c.k.x"
        target="_blank"
        rel="noopener noreferrer"
      >
        @LINDEDERI.C.K.X 
      </a>
      <br />
      EMAIL:{" "}
      <a
        href="mailto:info@lindederickx.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        INFO@LINDEDERICKX.COM
      </a>
      <br />
      PHONE: +31 6 52 64 94 38
      <br />
    </AntdFooter>
  );
};
