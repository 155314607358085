import { Align } from "components/Align";
import { Box } from "components/Box";
import { Grid } from "components/Grid";
import { Image } from "components/Image";
import React from "react";

export const Artwork = () => {
  const content = [
    <Grid
      elements={[
        <Image path="/images/artwork/IMG_0696.jpg" />,
        <Image path="/images/artwork/IMG_0698.jpg" />,
      ]}
    />,
    {
      textAlign: "center",
      className: "title",
      children: "Deform, 2019",
    },
    {
      textAlign: "center",
      children: <Image path="/images/artwork/2.jpg" />,
    },
    { textAlign: "center", className: "title", children: "Bark, 2015" },
    <Grid
      elements={[
        [
          <Image path="/images/artwork/1.jpg" />,
          <Box textAlign="center" className="title">
            2017
          </Box>,
        ],
        [
          <Image key={0} path="/images/artwork/IMG_0506.jpg" max="100%" />,
          <Box textAlign="center" className="title">
            Clouds & airplane stripes, 2017
          </Box>,
          <Image key={1} path="/images/artwork/IMG_0510.jpg" />,
        ],
      ]}
    />,
    <Grid
      elements={[
        [
          <Image path="/images/artwork/IMG_5369.jpg" />,
          <Box textAlign="center" className="title">
            Belt, 2015
          </Box>,
        ],
        [
          <Image path="/images/artwork/IMG_0516.jpg" />,
          <Box textAlign="center" className="title">
            Clouds, 2015
          </Box>,
        ],
      ]}
    />,
    {
      textAlign: "center",
      children: <Image path="/images/artwork/IMG_3156.jpg" />,
    },
    {
      textAlign: "center",
      className: "title",
      children: "Williamsburg sky, 2015",
    },
    <Grid
      elements={[
        <Image path="/images/artwork/IMG_0518.jpg" />,
        <Image path="/images/artwork/IMG_0519.jpg" />,
      ]}
    />,
    { textAlign: "center", className: "title", children: "Framed, 2016" },
    {
      textAlign: "center",
      children: <Image path="/images/artwork/IMG_0521.jpg" />,
    },
  ];

  return <Align elements={content} />;
};
