import { Link, Route, Switch } from "react-router-dom";

import { Box } from "components/Box";
import { DuoColor } from "pages/Content/Tables/DuoColor";
import { Grid } from "components/Grid";
import { Image } from "components/Image";
import { IvoryWhite } from "pages/Content/Tables/IvoryWhite";
import { MAX_COLUMN_WIDTH } from "utils/config";
import React from "react";
import { WineRed } from "pages/Content/Tables/WineRed";

export const Tables = () => {
  return (
    <Switch>
      <Route path="/tables/wine-red" component={WineRed} />
      <Route path="/tables/ivory-white" component={IvoryWhite} />
      <Route path="/tables/duo-color" component={DuoColor} />
      <Route>
        <Grid
          elements={[
            <Box textAlign="justify" alignSelf='start'>
              <p className="title">DE-FORMA</p>
              <p>
                A red vein curling on a rocky surface was the design inspiration
                for the DE-FORMA salon table. By recreating and deconstructing
                the positive and negative shapes from that original surface,
                DE-FORMA was born.
              </p>
              <p>
                One salon table consisting of two separate tables, the DE table
                and the FORMA table. The DE-FORMA salon table can be arranged
                into three different curvaceous compositions from small to
                medium and large.
                <br />
                Due to it’s virtual weightlessness DE-FORMA is easily moved,
                de-formed and re-formed into any environment. The smooth sides
                can be connect together as the sides with vertical cannelures
                are always exposed. With it’s sculptural and architectural
                characteristics it invites endless inspiration, play, and
                exploration.
              </p>
              <Box
                width="100%"
                forwardedAs="img"
                alt=""
                src={process.env.PUBLIC_URL + "/images/tables/thumb_d09.jpg"}
              />
            </Box>,
            <Box>
              <Grid
                elements={[
                  <Link to="tables/wine-red">
                    <Box textAlign="center" className="title">
                      <Image
                        path="/images/tables/DSC01072.jpg"
                        max={`${MAX_COLUMN_WIDTH * 2}px`}
                      />
                      <br />
                      DE-FORMA wine red
                    </Box>
                  </Link>,
                  <Link to="tables/ivory-white">
                    <Box textAlign="center" className="title">
                      <Image
                        path="/images/tables/DSC01159.jpg"
                        max={`${MAX_COLUMN_WIDTH * 2}px`}
                      />
                      <br />
                      DE-FORMA ivory white
                    </Box>
                  </Link>,
                  <Link to="tables/duo-color">
                    <Box textAlign="center" className="title">
                      <Image
                        path="/images/tables/DSC01051.jpg"
                        max={`${MAX_COLUMN_WIDTH * 2}px`}
                      />
                      <br />
                      DE-FORMA duo color
                    </Box>
                  </Link>,
                ]}
              />
            </Box>,
          ]}
        />
      </Route>
    </Switch>
  );
};
