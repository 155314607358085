import { Align } from "components/Align";
import { Box } from "components/Box";
import { Form } from "components/Form";
import { Grid } from "components/Grid";
import { Image } from "components/Image";
import { MAX_COLUMN_WIDTH } from "utils/config";
import React from "react";

export const Mirrors = () => {
  const content = [
    <Grid
      elements={[
        <Box textAlign="justify" alignSelf="start">
          <p className="title">NEBULA Mirror Series</p>
          <p>
            The constant variation of clouds in shape, structure and character
            is the inspiration for the handmade NEBULA Mirror Series. They can
            look hazy, puffy, bright white or dark as a thunderstorm ready to
            strike it’s lightning. The NUBES NOVEM and the ATRA NUBE mirror
            represent these stark opposites. Either designed in a happy,
            colorful, light version or a darker, minimal finish for a clean
            design.
          </p>
          <p>
            The NEBULA mirror is available in two editions. The NUBES NOVEM in a
            rope contouring available in different colors and the ATRA NUBE in a
            black rubber finish.
          </p>
          <Box
            width="100%"
            forwardedAs="img"
            alt=""
            src={process.env.PUBLIC_URL + "/images/mirrors/IMG_3551.jpg"}
          />
          <Box textAlign="center" className="title">
            NUBES NOVEM
          </Box>
        </Box>,
        <Box>
          <Grid
            elements={[
              <Box textAlign="center" className="title">
                <Image path="/images/mirrors/IMG_3199.jpg" />
                <br />
                ATRA NUBES
              </Box>,
              <Box textAlign="center" className="title">
                <Image path="/images/mirrors/IMG_3500.jpg" />
                <br />
                NUBES NOVEM
              </Box>,
            ]}
          />
        </Box>,
      ]}
    />,
    {
      textAlign: "center",
      children: "Choose your NEBULA shape from the following 5 options:",
    },
    <Grid
      elements={[
        <Image path="/images/mirrors/nebula-size-manual.svg" />,
        <Box textAlign="justify" fontSize='14px'>
          <p>
            NEBULA mirrors compact-shape 01, 02, 03 available in the following
            sizes:
            <table>
              <tr>
                <td>Small:</td>
                <Box forwardedAs="td" pl="24px">
                  40 x 35 cm
                </Box>
              </tr>
              <tr>
                <td>Medium:</td>
                <Box forwardedAs="td" pl="24px">
                  60 x 55 cm
                </Box>
              </tr>
              <tr>
                <td>Large:</td>
                <Box forwardedAs="td" pl="24px">
                  80 x 75 cm
                </Box>
              </tr>
              <tr>
                <td>Extra large:</td>
                <Box forwardedAs="td" pl="24px">
                  100 x 95 cm
                </Box>
              </tr>
            </table>
          </p>
          <br/>
          <p>
            NEBULA mirrors fitting-shape 04, 05 available in the following
            sizes:
            <table>
              <tr>
                <td>Medium:</td>
                <Box forwardedAs="td" pl="24px">
                  90 x 30 cm
                </Box>
              </tr>
              <tr>
                <td>Large:</td>
                <Box forwardedAs="td" pl="24px">
                  130 x 40 cm
                </Box>
              </tr>
              <tr>
                <td>Extra large:</td>
                <Box forwardedAs="td" pl="24px">
                  160 x 50 cm
                </Box>
              </tr>
            </table>
          </p>
          <br/>
          <p>
            Available in different finishing: Black rubber, costumized rope in
            any color.
          </p>
          <p>Year: 2020</p>
          <p>
            All mirrors are handmade, sizing can slightly differ from one to another.
          </p>
        </Box>,
      ]}
    />,
    {
      textAlign: "center",
      children:
        "For orders and price inquiries, please fill out the following informations:",
    },
    {
      maxWidth: `${MAX_COLUMN_WIDTH * 1.5}px`,
      mx: "auto",
      children: <Form />,
    },
  ];

  return <Align elements={content} />;
};
