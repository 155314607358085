import { Button, Dropdown, Layout, Menu } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Box } from "components/Box";
import CKX from "pages/Menu/C K X.svg";
import { MenuOutlined } from "@ant-design/icons";
import React from "react";

const { Header: AntdHeader } = Layout;
const { SubMenu } = Menu;

export const Header = () => {
  let history = useHistory();
  let location = useLocation();
  const currentTab = location?.pathname?.split("/")?.[1];

  const handleClick = (event) => {
    if (event.key) {
      history.push(`/${event.key}`);
    }
  };

  return (
    <AntdHeader
      style={{ display: "flex", alignItems: "center", padding: "0 24px" }}
    >
      <Link to="/">
        <Box fontWeight="bold" fontSize="24px">
          <Box forwardedAs="img" alt="C K X" width="80px" src={CKX} />
        </Box>
      </Link>
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu selectedKeys={[currentTab]} onClick={handleClick}>
            <SubMenu key="products" title="products">
              <Menu.Item key="tables">tables</Menu.Item>
              <Menu.Item key="mirrors">mirrors</Menu.Item>
              <Menu.Item key="objects">objects</Menu.Item>
              <Menu.Item key="artwork">artwork</Menu.Item>
            </SubMenu>
            <Menu.Item key="about">about C K X</Menu.Item>
            <Menu.Item key="contact">contact</Menu.Item>
          </Menu>
        }
      >
        <Box marginLeft="auto">
          <Button type="text" shape="circle" icon={<MenuOutlined />} />
        </Box>
      </Dropdown>
    </AntdHeader>
  );
};
