import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { About } from "pages/Content/About";
import { Artwork } from "pages/Content/Artwork";
import { Box } from "components/Box";
import { Contact } from "pages/Content/Contact";
import { Home } from "pages/Content/Home";
import { Layout } from "antd";
import { Mirrors } from "pages/Content/Mirrors";
import { Objects } from "pages/Content/Objects";
import { Tables } from "pages/Content/Tables";
import { ThankYou } from "pages/Content/ThankYou";
import { useWindowSize } from "hooks/useWindowSize";

const { Content: AntdContent } = Layout;

export const Content = () => {
  const { pathname } = useLocation();
  const { smallWindow } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AntdContent>
      <Box m={`24px 24px 24px ${smallWindow ? "24px" : "0"}`}>
        <Switch>
          <Route path="/tables" component={Tables} />
          <Route path="/mirrors" component={Mirrors} />
          <Route path="/objects" component={Objects} />
          <Route path="/artwork" component={Artwork} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/thank-you" component={ThankYou} />
          <Route component={Home} />
        </Switch>
      </Box>
    </AntdContent>
  );
};
