import { Align } from "components/Align";
import { Box } from "components/Box";
import { Form } from "components/Form";
import { Image } from "components/Image";
import { MAX_COLUMN_WIDTH } from "utils/config";
import React from "react";

export const Contact = () => {
  return (
    <Align
      elements={[
        <Image path="/images/tables/DSC01468.jpg" max="100%" />,
        { className: "title", children: "CONTACT" },
        <Box>
          Linde Derickx
          <br />
          Location: The Netherlands
        </Box>,
        <Box>
          Instagram:{" "}
          <a
            href="https://www.instagram.com/lindederi.c.k.x"
            target="_blank"
            rel="noopener noreferrer"
          >
            @lindederi.c.k.x
          </a>
          <br />
          Email:{" "}
          <a
            href="mailto:info@lindederickx.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@lindederickx.com
          </a>
          <br />
          Phone: +31 6 52 64 94 38
        </Box>,
        { className: "title", children: "INQUIRE" },
        {
          textAlign: "justify",
          children:
            "All products are made to order and can be customized, please fill out the following informations:",
        },
        {
          maxWidth: `${MAX_COLUMN_WIDTH * 1.5}px`,
          children: <Form />,
        },
      ]}
    />
  );
};
