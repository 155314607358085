import styled, { css, } from '@xstyled/styled-components'

export const Box = styled.box`
  ${(p) =>
    css`
      transform: ${p.transform};
      cursor: ${p.cursor};
      white-space: ${p.whiteSpace};
      text-overflow: ${p.textOverflow};
      pointer-events: ${p.pointerEvents};
      text-decoration: ${p.textDecoration};
      object-fit: ${p.objectFit};
    `}
`
