import { BrowserRouter, useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box } from "components/Box";
import CKX from "pages/Menu/C K X.svg";
import { Content } from "pages/Content";
import { Footer } from "pages/Footer";
import { Header } from "pages/Header";
import { Layout } from "antd";
import { Menu } from "pages/Menu";
import { useWindowSize } from "hooks/useWindowSize";

const MENU_WIDTH = 300;

const NUMBER_OF_VIDEOS = 4;

const LENGTH_OF_VIDEO = 8;

const videoPath = `/videos/ckx_${
  Math.floor(Math.random() * NUMBER_OF_VIDEOS) + 1
}.mp4`;

const Container = () => {
  const { smallWindow } = useWindowSize();
  const { pathname } = useLocation();

  return (
    <Layout>
      {!smallWindow && <Menu MENU_WIDTH={MENU_WIDTH} />}
      <Layout style={{ marginLeft: smallWindow ? 0 : MENU_WIDTH }}>
        {smallWindow && <Header />}
        <Content />
        {!pathname.includes("contact") && <Footer />}
      </Layout>
    </Layout>
  );
};

export const App = () => {
  const { smallWindow } = useWindowSize();
  const [videoIsPlaying, setVideoIsPlaying] = useState(!smallWindow);
  const videoElement = useRef(null);

  const siteIsLive = useMemo(
    () =>
      Boolean(
        window.location.host.includes("netlify") ||
          window.location.host.includes("localhost") ||
          new Date().toJSON().slice(0, 10) >= "2020-08-31"
      ),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setVideoIsPlaying(false);
    }, LENGTH_OF_VIDEO * 1000);
  }, []);

  useEffect(() => {
    if (videoElement.current) {
      setTimeout(() => {
        if (videoElement?.current?.style) {
          videoElement.current.style.opacity = 0;
        }
      }, (LENGTH_OF_VIDEO - 0.5) * 1000);
    }
  }, [videoElement]);

  return siteIsLive ? (
    <>
      {videoIsPlaying && (
        <Box
          onClick={() => setVideoIsPlaying(false)}
          cursor="pointer"
          display="flex"
          position="fixed"
          zIndex={1}
          top="0px"
          left="0px"
          right="0px"
          bottom="0px"
          backgroundColor="#fafafa"
        >
          <Box
            ref={videoElement}
            id="loading-video"
            transition="all 1s"
            opacity={1}
            forwardedAs="video"
            objectFit="cover"
            autoPlay
            width="100%"
            muted
            playsInline
          >
            <source src={process.env.PUBLIC_URL + videoPath} type="video/mp4" />
          </Box>
        </Box>
      )}
      <BrowserRouter>
        <Container />
      </BrowserRouter>
    </>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Box forwardedAs="img" alt="C K X" width="200px" mx="auto" src={CKX} />
      <Box my="50px" fontSize="18px">
        COMING SOON
      </Box>
      <Box>August 31, 2020</Box>
    </Box>
  );
};
